<template>
  <div class="page portfolio" dataxpos="1" dataypos="1">
    <span class="page-nav hover nav-left left_">
      <BaseIcon name="about" class="mr" /> About
    </span>

    <span class="page-nav hover nav-down down_"
      ><BaseIcon name="contact" class="mr" /> Contact</span
    >

    <span class="page-nav hover nav-left-down down left">
      <BaseIcon name="home" class="mr" /> Home
    </span>

    <vue-simplebar class="content">
      <div class="header-page mt-70">
        <h2>Portfolio</h2>
        <BaseIcon name="galstuk" />
      </div>

      <div class="row portfolio-items mt-70 mb-70">
        <ProjectCard
          v-for="(project, index) in projects"
          :key="index"
          :project="project"
          :class="[project.category]"
        />
      </div>
    </vue-simplebar>
  </div>
</template>

<script>
import ProjectCard from "@/components/ProjectCard.vue";
import BaseIcon from "@/components/BaseIcon";

export default {
  name: "Portfolio",
  components: {
    ProjectCard,
    BaseIcon
  },
  data() {
    return {
      projects: [
        {
          name: "Qitchen",
          img: {
            png: require("@/assets/img/portfolio/qitchen.png"),
            webp: require("@/assets/img/portfolio/qitchen.webp")
          },
          category: "web",
          link: "https://qitchen.netlify.app/"
        },
          {
          name: "United Laboratories Company",
          img: {
            png: require("@/assets/img/portfolio/ulc.png"),
            webp: require("@/assets/img/portfolio/ulc.webp")
          },
          category: "web",
          link: "https://ul-com.com/"
        },
        {
          name: "Premium Pro",
          img: {
            png: require("@/assets/img/portfolio/premium-pro.png"),
            webp: require("@/assets/img/portfolio/premium-pro.webp")
          },
          category: "web",
          link: "https://premiumpro.world/"
        },
        {
          name: "Slib",
          img: {
            png: require("@/assets/img/portfolio/slib.png"),
            webp: require("@/assets/img/portfolio/slib.webp")
          },
          category: "web",
          link: "https://slib.uz/ru/"
        },
        {
          name: "Mobil Pay",
          img: {
            png: require("@/assets/img/portfolio/mobilpay.png"),
            webp: require("@/assets/img/portfolio/mobilpay.webp")
          },
          category: "web",
          link: "https://mobilpay.uz/"
        },
        {
          name: "Grant Mininnavation",
          img: {
            png: require("@/assets/img/portfolio/grant-mininnovation.png"),
            webp: require("@/assets/img/portfolio/grant-mininnovation.webp")
          },
          category: "web",
          link: "https://grant.mininnovation.uz/"
        },
        {
          name: "Grantgo",
          img: {
            png: require("@/assets/img/portfolio/grantgo.png"),
            webp: require("@/assets/img/portfolio/grantgo.webp")
          },
          category: "web",
          link: "https://grantgo.uz/"
        },
        {
          name: "chakana.uz",
          img: {
            png: require("@/assets/img/portfolio/chakana.png"),
            webp: require("@/assets/img/portfolio/chakana.webp")
          },
          category: "web",
          link: "https://chakana.uz/"
        },
        {
          name: "asaxiy.uz",
          img: {
            png: require("@/assets/img/portfolio/asaxiy.uz.png"),
            webp: require("@/assets/img/portfolio/asaxiy.uz.webp")
          },
          category: "web",
          link: "https://asaxiy.uz/"
        },
        {
          name: "Bazarchik",
          img: {
            png: require("@/assets/img/portfolio/bazarchik.png"),
            webp: require("@/assets/img/portfolio/bazarchik.webp")
          },
          category: "web",
          link: "https://bazarchik.uz/"
        },
        {
          name: "Codeplay",
          img: {
            png: require("@/assets/img/portfolio/codeplay.png"),
            webp: require("@/assets/img/portfolio/codeplay.webp")
          },
          category: "web",
          link: "https://codeplay.uz/"
        },
        {
          name: "Countries",
          img: {
            png: require("@/assets/img/portfolio/countries.png"),
            webp: require("@/assets/img/portfolio/countries.webp")
          },
          category: "web",
          link: "https://rcountries.netlify.app/"
        },
        {
          name: "Iron Man",
          img: {
            png: require("@/assets/img/portfolio/iron-man.png"),
            webp: require("@/assets/img/portfolio/iron-man.webp")
          },
          category: "design",
          link: "https://murodbek-norboyev.github.io/iron-man/"
        }
        // {
        //   name: "Weather app",
        //   img: require("@/assets/img/portfolio/weather.png"),
        //   category: "web",
        //   link: "https://weatherthisday.netlify.app/"
        // },
        // {
        //   name: "Last news",
        //   img: require("@/assets/img/portfolio/news-page.png"),
        //   category: "web",
        //   link: "https://murodbek-norboyev.github.io/last-news/",
        // },
        // {
        //   name: "My wallet",
        //   img: require("@/assets/img/portfolio/wallets-page.png"),
        //   category: "web",
        //   link: "https://murodbek-norboyev.github.io/my-wallet/",
        // },
        // {
        //   name: "iWatch",
        //   img: require("@/assets/img/portfolio/iWatch.png"),
        //   category: "web",
        //   link: "https://murodbek-norboyev.github.io/iWatch/",
        // },
        // {
        //   name: "Pokemons",
        //   img: require("@/assets/img/portfolio/pokemons-page.png"),
        //   category: "web",
        //   link: "https://murodbek-norboyev.github.io/pokemons/",
        // },
        // {
        //   name: "Movies",
        //   img: require("@/assets/img/portfolio/movies-page.png"),
        //   category: "web",
        //   link: "https://murodbek-norboyev.github.io/search-movies/",
        // },
        // {
        //   name: "Jobest",
        //   img: require("@/assets/img/portfolio/jobest-page.png"),
        //   category: "web",
        //   link: "https://murodbek-norboyev.github.io/jobest/",
        // },
        // {
        //   name: "Registration",
        //   img: require("@/assets/img/portfolio/registr.png"),
        //   category: "web",
        //   link: "https://murodbek-norboyev.github.io/form/",
        // },
        // {
        //   name: "The page of pleasure",
        //   img: require("@/assets/img/portfolio/lazzat-page.png"),
        //   category: "web",
        //   link: "https://murodbek-norboyev.github.io/lazzat/",
        // },
      ]
    };
  }
};
</script>
