<template>
  <div class="item hover col-lg-4 col-sm-6">
    <figure
        v-tilt="{
        maxTilt: 10,
        speed: 400,
        perspective: 500,
        maxGlare: 0.6,
        reverse: true,
      }"
    >
      <picture>
        <source :srcset="project.img.png" type="image/png">
        <source :srcset="project.img.webp" type="image/webp">
        <img :src="project.img.png" :alt="project.name" width="566" height="566"/>
      </picture>
      <figcaption>
        <h3>{{ project.name }}</h3>
        <p><i class="fas fa-filter"></i> {{ project.category }}</p>
        <BaseIcon name="globus"/>
        <a :href="project.link" :aria-label="`Visit to ${project.link}`" target="_blank"></a>
      </figcaption>
    </figure>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon"

export default {
  name: "ProjectCard",
  components: {
    BaseIcon,
  },
  props: {
    project: Object,
  },
}
</script>

<style scoped>

</style>
