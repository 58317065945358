import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource'
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import VueSimplebar from 'vue-simplebar'
import 'vue-simplebar/dist/vue-simplebar.min.css'
import VueParticles from 'vue-particles'
import Vuelidate from 'vuelidate'
import VueTilt from 'vue-tilt.js'
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import './registerServiceWorker'

Vue.use(VueViewer)
Vue.use(VueTilt)
Vue.use(VueParticles)
Vue.use(VueSimplebar)
Vue.use(VueResource)
Vue.use(Vuelidate)

Vue.config.productionTip = false

const requireComponent = require.context(
    './components',
    false,
    /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName)

    const componentName = upperFirst(
        camelCase(
            fileName
                .split('/')
                .pop()
                .replace(/\.\w+$/, '')
        )
    )

    Vue.component(componentName, componentConfig.default || componentConfig)
})

new Vue({
    render: h => h(App),
}).$mount('#app')
