<template>
  <div class="page" dataxpos="0" dataypos="0">
    <div class="background"></div>

    <!-- Particles -->
    <vue-particles
        color="#dedede"
        :particleOpacity="0.5"
        :particlesNumber="100"
        shapeType="circle"
        :particleSize="4"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="4"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"/>

    <!-- Banner -->
    <div class="banner">
      <h1>Norboyev Murodbek</h1>
      <h2>I am a Frontend Developer</h2>
    </div>

    <!-- Menu -->
    <span class="page-nav nav-left-top up left hover">
      <BaseIcon name="about" class="mr"/> About
    </span>

    <span class="page-nav nav-left-down down left hover">
      <BaseIcon name="certificate" class="mr"/> Certificates
    </span>

    <span class="page-nav nav-right-top up right hover">
      Portfolio
      <BaseIcon name="portfolio" class="ml"/>
    </span>

    <span class="page-nav nav-right-down down right hover">
      Contact
      <BaseIcon name="contact" class="ml"/>
    </span>

    <!-- Social -->
    <div class="social">
      <ul>
        <li
            class="hover magnetic"
            data-tooltip="Telegram"
            data-position="bottom"
        >
          <a href="https://t.me/wayfarer_official" aria-label="Visit to https://t.me/wayfarer_official" target="_blank"
          ><i class="fab fa-telegram-plane"></i>
          </a>
        </li>
        <li
            class="hover magnetic"
            data-tooltip="Instagram"
            data-position="bottom"
        >
          <a href="https://www.instagram.com/n_murodbek_/" aria-label="Visit to https://www.instagram.com/n_murodbek_/" target="_blank">
            <i class="fab fa-instagram"></i>
          </a>
        </li>
        <li class="hover magnetic" data-tooltip="Gmail" data-position="bottom">
          <a href="mailto:murodbest19@gmail.com/" aria-label="Mail to murodbest19@gmail.com/" target="_blank">
            <i class="fab fa-google"></i>
          </a>
        </li>
        <li class="hover magnetic" data-tooltip="Github" data-position="bottom">
          <a href="https://github.com/murodbek-norboyev/" aria-label="Visit to https://github.com/murodbek-norboyev/" target="_blank">
            <i class="fab fa-github-alt"></i>
          </a>
        </li>
        <li
            class="hover magnetic"
            data-tooltip="Codepen"
            data-position="bottom"
        >
          <a href="https://codepen.io/murad-developer" aria-label="Visit to https://codepen.io/murad-developer" target="_blank">
            <i class="fab fa-codepen"></i>
          </a>
        </li>
        <li
            class="hover magnetic"
            data-tooltip="Linkedin"
            data-position="bottom"
        >
          <a href="https://linkedin.com/in/murodbek-norboyev/" aria-label="Visit to https://linkedin.com/in/murodbek-norboyev/" target="_blank">
            <i class="fab fa-linkedin-in"></i>
          </a>
        </li>
        <li
            class="hover magnetic"
            data-tooltip="Twitter"
            data-position="bottom"
        >
            <a href="https://twitter.com/wayfarer0799/" aria-label="Visit to https://twitter.com/wayfarer0799/" target="_blank">
            <i class="fab fa-twitter"></i>
          </a>
        </li>
        <li
            class="hover magnetic"
            data-tooltip="Facebook"
            data-position="bottom"
        >
          <a href="https://facebook.com/norboevmurod/" aria-label="Visit to https://facebook.com/norboevmurod/" target="_blank">
            <i class="fab fa-facebook"></i>
          </a>
        </li>
      </ul>
    </div>

    <!-- Logo -->
    <div class="logo hover"></div>

    <!-- Icon "Full Menu" -->
    <div
        class="page-zoom zoom magnetic hover"
        data-tooltip="View all pages"
        data-position="bottom">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <!-- Page Transition List -->
    <div
        class="animation-wrap hover"
        @mouseenter="animMenuOpen"
        @mouseleave="animMenuOpen"
        data-tooltip="Page Transition"
        data-position="right"
    >
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          class="show-fade magnetic"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <rect x="0" y="0" width="24" height="24"></rect>
          <path
              d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z"
              style="fill: var(--primary-color)"
              opacity="0.3"
          ></path>
          <path
              d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z"
              style="fill: var(--primary-color)"
          ></path>
        </g>
      </svg>

      <ul class="animation-list" v-bind:class="{ active: isActive }">
        <li>
          <span class="hover animation" data-animation="animate-none"
          >None</span
          >
        </li>
        <li>
          <span class="hover animation" data-animation="animate-one">One</span>
        </li>
        <li>
          <span class="hover animation active" data-animation="animate-two"
          >Two</span
          >
        </li>
        <li>
          <span class="hover animation" data-animation="animate-three"
          >Three</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      isActive: false,
      interactivity: {
        detect_on: 'canvas',
        events: {
          onhover: {enable: true, mode: 'repulse'},
          onclick: {enable: true, mode: 'push'},
          resize: true
        },
        modes: {
          grab: {distance: 400, line_linked: {opacity: 1}},
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3
          },
          repulse: {distance: 200, duration: 0.4},
          push: {particles_nb: 4},
          remove: {particles_nb: 2}
        }
      },
      retina_detect: true
    }
  },
  methods: {
    animMenuOpen: function () {
      this.isActive = !this.isActive
    }
  }
}
</script>
