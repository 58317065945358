<template>
  <svg class="icon" :width="width" :height="height">
    <use v-bind="{ 'xlink:href': '/icons.svg#' + name }"/>
  </svg>
</template>

<script>
export default {
  props: {
    name: String,
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    }
  }
}
</script>
