<template>
  <div class="page gallery" dataxpos="-1" dataypos="-1">
    <span class="page-nav hover nav-up up_">
      <BaseIcon name="about" class="mr"/>
      About
    </span>

    <span class="page-nav hover nav-right right_">
      <BaseIcon name="contact" class="mr"/>
      Contact
    </span>

    <span class="page-nav hover nav-right-top up right">
      Home
      <BaseIcon name="home" class="ml"/>
    </span>

    <vue-simplebar class="content">
      <!-- Section Title -->
      <div class="header-page mt-70">
        <h2>Certificates</h2>
        <BaseIcon name="picture"/>
      </div>

      <viewer
          class="row loadmore mt-100 mb-20"
          :certificates="certificates"
          :options="options"
      >
        <CertificateCard
            v-for="(certificate, index) in certificates"
            :key="index"
            :certificate="certificate"
        />
      </viewer>
    </vue-simplebar>
  </div>
</template>

<script>
import CertificateCard from "@/components/CertificateCard.vue"
import BaseIcon from "@/components/BaseIcon"

export default {
  name: "Certificate",
  components: {
    CertificateCard,
    BaseIcon
  },
  data() {
    return {
      certificates: [
        {
          name: "hackerrank",
          img: {
            png: require("@/assets/img/certificates/hackerrank.png"),
            webp: require("@/assets/img/certificates/hackerrank.webp"),
          }
        },
        {
          name: "freecodecamp",
          img: {
            png: require("@/assets/img/certificates/freecodecamp.png"),
            webp: require("@/assets/img/certificates/freecodecamp.webp"),
          }
        },
        {
          name: "solo-jquery",
          img: {
            png: require("@/assets/img/certificates/solo-jquery.png"),
            webp: require("@/assets/img/certificates/solo-jquery.webp"),
          }
        },
        {
          name: "solo-js",
          img: {
            png: require("@/assets/img/certificates/solo-js.png"),
            webp: require("@/assets/img/certificates/solo-js.webp"),
          }
        },
        {
          name: "solo-css",
          img: {
            png: require("@/assets/img/certificates/solo-css.png"),
            webp: require("@/assets/img/certificates/solo-css.webp"),
          }
        },
        {
          name: "solo-html",
          img: {
            png: require("@/assets/img/certificates/solo-html.png"),
            webp: require("@/assets/img/certificates/solo-html.webp"),
          }
        },
      ],
      options: {
        navbar: false,
        movable: false,
        title: false,
        toolbar: false,
      },
    }
  },
  methods: {
    show() {
      this.$viewerApi({
        certificates: this.certificate.img,
      })
    },
  }
}
</script>

<style scoped>

</style>
