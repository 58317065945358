<template>
  <div class="preloader">
    <span><img :src="require('@/assets/img/logo_2.svg')" alt="logo" width="100"></span>
    <p>0</p>
  </div>
</template>

<script>
import {TweenMax} from 'gsap/TweenMax'
import {Power2} from "gsap";

export default {
  mounted() {
    this.Preloader();
  },
  methods: {
    Preloader: function () {
      let counter = 0;
      let c = 0;
      let i = setInterval(function () {
        let pre = document.querySelector('.preloader p');
        pre.innerHTML = (c);

        c++;
        counter++;
        if (counter == 101) {
          clearInterval(i);
          TweenMax.from('.preloader p', 1, {
            ease: Power2.easeInOut
          });
          TweenMax.to('.preloader p', 1, {
            opacity: 0,
            ease: Power2.easeInOut
          });
          TweenMax.from('.preloader span', 1, {
            ease: Power2.easeInOut
          });
          TweenMax.to('.preloader span', 1, {
            opacity: 0,
            ease: Power2.easeInOut
          });
          TweenMax.from('.preloader', 2, {
            ease: Power2.easeInOut
          });
          TweenMax.to('.preloader', 2, {
            top: '-120%',
            ease: Power2.easeInOut
          });
        }
      }, 9);
    }
  }
}
</script>

<style scoped>

</style>
