<template>
  <div class="wrap">
    <div class="page-wrap animate-none">
      <Home/>
      <About/>
      <Portfolio/>
      <Certificate/>
      <Contacts/>
    </div>
  </div>
</template>

<script>
import Home from "@/views/Home";
import Portfolio from "@/views/Portfolio";
import Certificate from "@/views/Certificate";
import About from "@/views/About";
import Contacts from "@/views/Contacts";

export default {
  name: "Main",
  components: {Contacts, About, Certificate, Portfolio, Home},
  mounted() {
    this.Menu()
  },
  methods: {
    Menu: function () {
      function hasClass(e, a) {
        return e.className.match(new RegExp('(\\s|^)' + a + '(\\s|$)'))
      }

      function addClass(e, a) {
        hasClass(e, a) || (e.className += ' ' + a)
      }

      function removeClass(e, a) {
        if (hasClass(e, a)) {
          const t = new RegExp('(\\s|^)' + a + '(\\s|$)')
          e.className = e.className.replace(t, ' ')
        }
      }

      function setPos() {
        (wrap.style.transform =
            'translateX(' + pos_x + '00%) translateY(' + pos_y + '00%)'),
            setTimeout(function () {
              removeClass(wrap, 'animate')
            }, 600)
      }

      function moveUp() {
        addClass(wrap, 'animate'), pos_y++, setPos()
      }

      function moveUp_() {
        addClass(wrap, 'animate'), (pos_y = 0), pos_y++, setPos()
      }

      function moveLeft() {
        addClass(wrap, 'animate'), pos_x++, setPos()
      }

      function moveLeft_() {
        addClass(wrap, 'animate'), (pos_x = 0), pos_x++, setPos()
      }

      function moveRight() {
        addClass(wrap, 'animate'), pos_x--, setPos()
      }

      function moveRight_() {
        addClass(wrap, 'animate'), (pos_x = -0), pos_x--, setPos()
      }

      function moveDown() {
        addClass(wrap, 'animate'), pos_y--, setPos()
      }

      function moveDown_() {
        addClass(wrap, 'animate'), (pos_y = -0), pos_y--, setPos()
      }

      function toggleAnimation(e) {
        for (let a = 0; a < animation.length; a++) {
          e === a
              ? (addClass(animation[a], 'active'),
                  addClass(wrap, animation[a].getAttribute('data-animation')))
              : (removeClass(animation[a], 'active'),
                  removeClass(wrap, animation[a].getAttribute('data-animation')))
        }
      }

      function zoomOut(e) {
        e.stopPropagation(), addClass(site, 'show-all')
        for (let a = 0; a < panel.length; a++) {
          panel[a].addEventListener('click', setPanelAndZoom)
        }
      }

      function setPanelAndZoom(e) {
        (pos_x = -e.target.getAttribute('dataxpos')),
            (pos_y = e.target.getAttribute('dataypos')),
            setPos(),
            zoomIn()
      }

      function zoomIn() {
        for (let e = 0; e < panel.length; e++) {
          panel[e].removeEventListener('click', setPanelAndZoom)
        }
        removeClass(site, 'show-all')
      }

      // const win = window
      const doc = document
      const site = doc.getElementsByClassName('wrap')[0]
      const wrap = doc.getElementsByClassName('page-wrap')[0]
      const panel = doc.getElementsByClassName('page')
      const zoom = doc.getElementsByClassName('zoom')
      const nav_up = doc.getElementsByClassName('up')
      const nav_up_ = doc.getElementsByClassName('up_')
      const nav_left = doc.getElementsByClassName('left')
      const nav_left_ = doc.getElementsByClassName('left_')
      const nav_right = doc.getElementsByClassName('right')
      const nav_right_ = doc.getElementsByClassName('right_')
      const nav_down = doc.getElementsByClassName('down')
      const nav_down_ = doc.getElementsByClassName('down_')
      const animation = doc.getElementsByClassName('animation')
      let pos_x = 0
      let pos_y = 0

      setPos()

      for (let x = 0; x < nav_up.length; x++) {
        nav_up[x].addEventListener('click', moveUp)
      }
      for (let x = 0; x < nav_up_.length; x++) {
        nav_up_[x].addEventListener('click', moveUp_)
      }
      for (let x = 0; x < nav_left.length; x++) {
        nav_left[x].addEventListener('click', moveLeft)
      }
      for (let x = 0; x < nav_left_.length; x++) {
        nav_left_[x].addEventListener('click', moveLeft_)
      }
      for (let x = 0; x < nav_right.length; x++) {
        nav_right[x].addEventListener('click', moveRight)
      }
      for (let x = 0; x < nav_right_.length; x++) {
        nav_right_[x].addEventListener('click', moveRight_)
      }
      for (let x = 0; x < nav_down.length; x++) {
        nav_down[x].addEventListener('click', moveDown)
      }
      for (let x = 0; x < nav_down_.length; x++) {
        nav_down_[x].addEventListener('click', moveDown_)
      }

      for (let x = 0; x < animation.length; x++) {
        !(function (e) {
          animation[e].addEventListener('click', () => {
            toggleAnimation(e)
          })
        })(x)
      }
      for (let x = 0; x < zoom.length; x++) {
        zoom[x].addEventListener('click', zoomOut)
      }
    }
  }
}
</script>
