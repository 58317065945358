<template>
  <div id="app" class="blue">
    <StyleOptions/>
    <Preloader/>
    <Main/>
    <Grab/>
  </div>
</template>

<script>


import Main from "@/views/Main";
import Preloader from "@/components/Preloader";
import StyleOptions from "@/components/StyleOptions";
import Grab from "@/components/Grab";

export default {
  name: 'App',
  components: {Grab, StyleOptions, Preloader, Main}
}
</script>

<style lang="scss">
@import "~@/assets/vendor/bootstrap.min.scss";
@import "~@/assets/vendor/feather.scss";
@import "~@/assets/scss/color.scss";
@import "~@/assets/scss/style.scss";
@import "~@/assets/scss/style-demo.scss";
</style>
