<template>
  <div class="page contact" dataxpos="1" dataypos="-1">
    <span class="page-nav hover nav-up up_">
      <BaseIcon name="portfolio" class="mr"/> Portfolio
    </span>

    <span class="page-nav hover nav-left left_">
      <BaseIcon name="certificate" class="mr"/> Certificates
    </span>

    <span class="page-nav hover nav-left-top up left">
      <BaseIcon name="home" class="mr"/> Home
    </span>

    <vue-simplebar class="content container">
      <div class="header-page mt-70">
        <h2>Contact</h2>
        <BaseIcon name="envelope"/>
      </div>

      <div class="row mt-100">
        <div class="col-lg-8 col-sm-12">
          <div class="contact-form mb-100">
            <form
                method="post"
                @submit.prevent="submit"
                class="box contact-valid"
            >
              <!-- Title Box -->
              <div class="title">Contact Form</div>

              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <input
                      id="name"
                      type="text"
                      v-model.trim="name"
                      class="hover cursor-pen form-control"
                      placeholder="Name *"
                  />
                  <small
                      class="helper-text error"
                      v-if="$v.name.$dirty && !$v.name.required"
                  >
                    Please enter your name
                  </small>

                  <small
                      class="helper-text error"
                      v-if="$v.name.$dirty && !$v.name.minLength"
                  >
                    Name must have at least
                    {{ $v.name.$params.minLength.min }} letters.
                  </small>
                </div>

                <div class="col-lg-6 col-sm-12">
                  <input
                      type="email"
                      v-model.trim="email"
                      class="hover cursor-pen form-control"
                      placeholder="Email *"
                  />
                  <small
                      class="helper-text error"
                      v-if="$v.email.$dirty && !$v.email.required"
                  >
                    Please enter your email address
                  </small>
                  <small
                      class="helper-text error"
                      v-else-if="$v.email.$dirty && !$v.email.email"
                  >
                    Please enter a specific email
                  </small>
                </div>

                <div class="col-lg-6 col-sm-12">
                  <input
                      type="number"
                      v-model.trim="phone"
                      class="hover cursor-pen form-control"
                      placeholder="Phone number *"
                  />
                  <small
                      class="helper-text error"
                      v-if="$v.phone.$dirty && !$v.phone.required"
                  >
                    Please enter a valid number.
                  </small>
                  <small
                      class="helper-text error"
                      v-if="$v.phone.$dirty && !$v.phone.minLength"
                  >
                    Name must have at least
                    {{ $v.phone.$params.minLength.min }} number.
                  </small>
                  <small
                      class="helper-text error"
                      v-if="$v.phone.$dirty && !$v.phone.maxLength"
                  >
                    Name must have at max
                    {{ $v.phone.$params.maxLength.max }} number.
                  </small>
                </div>

                <div class="col-lg-6 col-sm-12">
                  <input
                      type="text"
                      v-model.trim="tgUsername"
                      class="hover cursor-pen form-control"
                      placeholder="Telegram username"
                  />
                  <small
                      class="helper-text error"
                      v-if="$v.tgUsername.$dirty && !$v.tgUsername.required"
                  >
                    Please enter your username
                  </small>

                  <small
                      class="helper-text error"
                      v-if="$v.tgUsername.$dirty && !$v.tgUsername.minLength"
                  >
                    Name must have at least
                    {{ $v.tgUsername.$params.minLength.min }} letters.
                  </small>
                </div>

                <div class="col-lg-12 col-sm-12">
                  <textarea
                      v-model.trim="note"
                      class="hover cursor-pen form-control"
                      placeholder="Your Message"
                  ></textarea>
                  <small
                      class="helper-text error"
                      v-if="$v.note.$dirty && !$v.note.required"
                  >
                    Please enter your note
                  </small>
                </div>
                <div class="col-lg-12 col-sm-12 text-center">
                  <button
                      type="submit"
                      class="button hover"
                      data-text="Send massage"
                  >
                    <b></b>
                    <b></b>
                    <span>S</span><span>e</span><span>n</span><span>d</span>
                    <span>M</span><span>a</span><span>s</span><span>s</span
                  ><span>a</span><span>g</span><span>e</span>
                  </button>
                  <div id="loader">
                    <i class="fas fa-sync"></i>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <div class="error-messages">
                    <div id="success">
                      <i class="far fa-check-circle"></i>Thank you, your message
                      has been sent.
                    </div>
                    <div id="error">
                      <i class="far fa-times-circle"></i>Error occurred while
                      sending email. Please try again later.
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- Contact Info -->
        <div class="col-lg-4 col-sm-12">
          <div class="box contact-info mb-100">
            <!-- Title Box -->
            <div class="title">Contact Information</div>

            <div class="row">
              <!-- Info item -->
              <div class="col-lg-12 col-sm-12 info">
                <i class="fas fa-map-marked-alt"></i>
                <div class="desc">
                  <p>Tashkent, UZBEKISTAN</p>
                  <span>Addres</span>
                </div>
              </div>

              <!-- Info item -->
              <div class="col-lg-12 col-sm-12 info">
                <i class="fas fa-phone"></i>
                <div class="desc">
                  <p>+998 33 019 07 99</p>
                  <span>Phone</span>
                </div>
              </div>

              <!-- Info item -->
              <div class="col-lg-12 col-sm-12 info">
                <i class="fas fa-paper-plane"></i>
                <div class="desc">
                  <p>murodbek.norboyev@mail.ru</p>
                  <span>Email</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-simplebar>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon"
import {email, required, maxLength, minLength} from "vuelidate/lib/validators"

export default {
  name: "Contacts",
  components: {BaseIcon},
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      tgUsername: "",
      note: "",
      token: "1822880566:AAHxqanVTXmkWBea3ndfeznUTagIEx8a_fg",
      chatId: "857127786",
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
    email: {
      email,
      required,
    },
    phone: {
      required,
      minLength: minLength(12),
      maxLength: maxLength(12),
    },
    tgUsername: {
      required,
      minLength: minLength(3),
    },
    note: {
      required
    },
  },
  methods: {
    submit() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const message = `🧑‍💻: ${this.name}\n📬: ${this.email}\n☎️: ${this.phone}\n🔘: ${this.tgUsername}\n✍️: ${this.note}`
      this.$http
          .post(
              `https://api.telegram.org/bot${this.token}/sendMessage?chat_id=${this.chatId}&parse_mode=html&text=${message}`
          )
          .then(
              (response) => {
                    this.name = ""
                    this.email = ""
                    this.phone = ""
                    this.tgUsername = ""
                    this.note = ""
                    console.log("Succes", response)
              },
              (error) => {
                console.log(error)
              }
          )
    },
  },
}
</script>
