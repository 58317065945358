<template>
  <div>
    <div class="cursor" id="cursor"></div>
    <div class="cursor-border" id="cursor-border"></div>
  </div>
</template>

<script>
// import {TweenMax} from 'gsap/TweenMax'

export default {
  mounted() {
    this.cursorFunction()
  },
  methods: {

    cursorFunction: function () {
      let isMobile = false;
      const html = document.documentElement;

      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        html.classList.add("touch");
        isMobile = true;
      } else {
        html.classList.add("no-touch");
        isMobile = false;
      }

      // eslint-disable-next-line no-unused-vars
      let isMacLike = /(Mac)/i.test(navigator.platform);

      let cursor = {
        delay: 8,
        _x: 0,
        _y: 0,
        endX: (window.innerWidth / 2),
        endY: (window.innerHeight / 2),
        cursorVisible: true,
        cursorEnlarged: false,
        cursorBox: document.querySelector('.cursor'),
        cursorBorder: document.querySelector('.cursor-border'),

        init: function () {
          let body = document.querySelector("body");

          body.style.cursor = "none";

          // Set up element sizes
          this.cursorSize = this.cursorBox.offsetWidth;
          this.cursorBorderSize = this.cursorBorder.offsetWidth;

          this.setupEventListeners();
          this.animateDotOutline();
          // this.cursorPen();
          // this.cursorGrab();
        },

        setupEventListeners: function () {
          let self = this;

          // Cursor Hover
          Array.prototype.slice.call(document.querySelectorAll('.hover')).forEach(function (el) {
            el.addEventListener('mouseover', function () {
              self.cursorEnlarged = true;
              self.toggleCursorSize();
            });

            el.addEventListener('mouseout', function () {
              self.cursorEnlarged = false;
              self.toggleCursorSize();
            });
          });

          window.addEventListener('mousemove', function (e) {
            // Show the cursor
            self.cursorVisible = true;
            self.toggleCursorVisibility();

            // Position the dot
            self.endX = e.clientX;
            self.endY = e.clientY;
            self.cursorBox.style.top = self.endY + 'px';
            self.cursorBox.style.left = self.endX + 'px';
          });

          // Hide/show cursor
          // eslint-disable-next-line no-unused-vars
          window.addEventListener('mouseenter', function (e) {
            self.cursorVisible = true;
            self.toggleCursorVisibility();
            self.cursorBox.style.opacity = 1;
            self.cursorBorder.style.opacity = 1;
          });


          // eslint-disable-next-line no-unused-vars
          window.addEventListener('mouseleave', function (e) {
            self.cursorVisible = true;
            self.toggleCursorVisibility();
            self.cursorBox.style.opacity = 0;
            self.cursorBorder.style.opacity = 0;
          });
        },

        animateDotOutline: function () {
          let self = this;

          self._x += (self.endX - self._x) / self.delay;
          self._y += (self.endY - self._y) / self.delay;
          self.cursorBorder.style.top = self._y + 'px';
          self.cursorBorder.style.left = self._x + 'px';

          requestAnimationFrame(this.animateDotOutline.bind(self));
        },

        toggleCursorSize: function () {
          let self = this;

          if (self.cursorEnlarged) {
            self.cursorBox.classList.add('homing');
            self.cursorBorder.classList.add('homing');
          } else {
            self.cursorBox.classList.remove('homing');
            self.cursorBorder.classList.remove('homing');
          }
        },

        toggleCursorVisibility: function () {
          let self = this;

          if (self.cursorVisible) {
            self.cursorBox.style.opacity = 1;
            self.cursorBorder.style.opacity = 1;
          } else {
            self.cursorBox.style.opacity = 0;
            self.cursorBorder.style.opacity = 0;
          }
        },

        cursorGrab: function () {
          let self = this;
          let vCursorGrab = document.querySelector('.cursor-grab');

          // eslint-disable-next-line no-unused-vars
          vCursorGrab.addEventListener('mouseenter', () => {
            self.cursor.classList.add('grab');
          });

          // eslint-disable-next-line no-unused-vars
          vCursorGrab.addEventListener('mouseleave', () => {
            self.cursor.classList.remove('grab');
          });
        },

        cursorPen: function () {
          let self = this;
          let vCursorPen = document.querySelector('.cursor-pen');
          // eslint-disable-next-line no-unused-vars
          vCursorPen.addEventListener('mouseenter', () => {
            self.cursor.classList.add('pen');
            self.cursorBorder.classList.add('homing');
          });
          // eslint-disable-next-line no-unused-vars
          vCursorPen.addEventListener('mouseleave', () => {
            self.cursor.classList.remove('pen');
            self.cursorBorder.classList.remove('homing');
          });
        }
      }

      if (!isMobile) {
        cursor.init();
      }

      // document.addEventListener('mousemove', (e) => {
      //   let magnetic = document.querySelector('.magnetic');
      //
      //   magnetic.forEach(function () {
      //     if (!isMobile) {
      //       magnetic(this, e);
      //     }
      //   });
      // });

      // eslint-disable-next-line no-unused-vars
      // function magnetic(el, e) {
      //   let mX = e.pageX,
      //       mY = e.pageY;
      //   const obj = el;
      //
      //   const customDist = 20 * obj.data('dist') || 25,
      //       centerX = obj.offset().left + obj.width() / 2,
      //       centerY = obj.offset().top + obj.height() / 2;
      //
      //   let deltaX = Math.floor((centerX - mX)) * -.4,
      //       deltaY = Math.floor((centerY - mY)) * -.4;
      //
      //   let distance = calcDistance(obj, mX, mY);
      //
      //   if (distance < customDist) {
      //     TweenMax.to(obj, .4, {
      //       y: deltaY,
      //       x: deltaX
      //     });
      //   } else {
      //     TweenMax.to(obj, .4, {
      //       y: 0,
      //       x: 0
      //     });
      //   }
      // }

      // function calcDistance(elem, mouseX, mouseY) {
      //   return Math.floor(Math.sqrt(Math.pow(mouseX - (elem.offset().left + (elem.width() / 2)), 2) + Math.pow(mouseY - (elem.offset().top + (elem.height() / 2)), 2)));
      // }
    }
  }
}
</script>

<style scoped>

</style>
