<template>
  <div class="gallery-item col-lg-4 col-sm-6">
    <div class="gallery-item-img">
      <div class="thumbnail hover">
        <button class="image-link">
          <picture>
            <source :srcset="certificate.img.png" type="image/png">
            <source :srcset="certificate.img.webp" type="image/webp">
            <img :src="certificate.img.png" :alt="certificate.name" width="566" height="427"/>
          </picture>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CertificateCard",
  props: {
    certificate: Object,
  },
}
</script>
